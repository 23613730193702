import clsx from 'clsx';
import React from 'react';
import Apple from '../../../../public/assets/icons/apple.svg';
import Google from '../../../../public/assets/icons/google.svg';
import Facebook from '../../../../public/assets/icons/facebook.svg';
import Email from '../../../../public/assets/icons/email.svg';

export type ISocialLoginButtonProps = {
    network: 'google' | 'apple' | 'facebook' | 'email';
    children: string;
    disabled?: boolean
    onClick: () => void;
};

const SocialLoginButton = (props: ISocialLoginButtonProps) => {
    const { network, children, onClick, disabled } = props;

    const icon = () => {
        switch (network) {
            case 'apple':
                return <Apple className="w-[24px] h-[24px]" />;
            case 'email':
                return <Email className="w-[24px] h-[24px]" />;
            case 'google':
                return <Google className="w-[24px] h-[24px]" />;
            case 'facebook':
                return <Facebook className="w-[24px] h-[24px]" />;
            default:
                break;
        }
        return <Google />;
    };

    return (
        <button
            type="button"
            disabled={disabled}
            onClick={onClick}
            className={clsx(
                'group bg-white text-[22px] shadow-btn-default px-8 py-[5px] rounded-full enabled:cursor-pointer overflow-hidden relative border-grey5 border-[1px] w-full',
                'hover:enabled:text-grey hover:enabled:bg-main',
                'disabled:bg-grey6 disabled:text-blue5 disabled:opacity-60 disabled:!cursor-not-allowed [&_*]:disabled:!cursor-not-allowed',
                'text-md'
            )}
        >
            <div className="absolute left-[12px] top-[calc(50%-12px)]">{icon()}</div>
            {children}
        </button>
    );
};

export default SocialLoginButton;
