import { CMS } from '@/types/CMS';
import { Constants } from './Constants';

export function getLandingPageTrackingInfo(data: CMS.Page.PageListResponseDataItem) {
    if (data?.attributes?.landingPageSignupTracking) {
        return { [Constants.Track.KEY_LANADING_PAGE_TRACKING_COOKIE_NAME]: data?.attributes?.landingPageSignupTracking };
    }
    return undefined;
}

export function getLandingPageTrackingFromLocalStorage() {
    try {
        return localStorage.getItem(Constants.Track.KEY_LANADING_PAGE_TRACKING_COOKIE_NAME) || undefined;
    // eslint-disable-next-line no-empty
    } catch (error) {
    }
    return undefined;
}
