import { CMS } from '@/types/CMS';
import { Constants } from './Constants';

export function geSkipOnboardingInfo(data: CMS.Page.PageListResponseDataItem) {
    if (data?.attributes?.skipOnboardingOnSignup === true) {
        return { [Constants.Track.KEY_LANADING_PAGE_SKIP_ONBOARDING]: 'true' };
    }
    if (data?.attributes?.skipOnboardingOnSignup === false) {
        return { [Constants.Track.KEY_LANADING_PAGE_SKIP_ONBOARDING]: 'false' };
    }
    return undefined;
}

export function getSkipOnboardingFromLocalStorage() {
    try {
        const val = localStorage.getItem(Constants.Track.KEY_LANADING_PAGE_SKIP_ONBOARDING) || undefined;
        if (val !== undefined && val !== undefined) {
            return val === 'true';
        }
    // eslint-disable-next-line no-empty
    } catch (error) {
    }
    return false;
}

export function setTempSkipOnboarding() {
    try {
        if (window) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).skipTempOnboard = true;
        }
    // eslint-disable-next-line no-empty
    } catch (error) {
    }
}

export function getTempSkipOnboarding() {
    try {
        if (window) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return (window as any).skipTempOnboard === true;
        }
    // eslint-disable-next-line no-empty
    } catch (error) {
    }
    return false;
}
