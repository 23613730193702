import { Auction, AuctionHistoryDto } from '@/api';
import AuctionManager from '@/helpers/AuctionManager';
import useSocket from '@/hooks/api/useSocket';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

export function AuctionListener() {
    const socket = useSocket();
    const queryClient = useQueryClient();

    useEffect(() => {
        const auctionListener = ({ auction, history }: { auction: Auction, history: AuctionHistoryDto }) => {
            AuctionManager.getInstance().updateAuction(auction);
            queryClient.setQueryData(['getAuctionBidHistoryQueryFn', auction?.id as string, 0], () => {
                return history;
            });
        };
        socket?.on('auction/updated', auctionListener);
        return () => {
            socket?.off('auction/updated', auctionListener);
        };
    }, [socket, queryClient]);
    return null;
}
