import Bugsnag from '@bugsnag/js';

export function startBugsnag() {
    if (!process.env.LOCAL && !Bugsnag.isStarted()) {
        Bugsnag.start({
            apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY ?? '',
            releaseStage: process.env.APP_ENV,
            onError(event) {
                if (
                    event.errors.find((e) =>
                        e.errorMessage.includes('timed out')
                    )
                ) {
                    return false;
                }
            },
        });
    }
}
