import React, { ErrorInfo } from 'react';
import Bugsnag from '@bugsnag/js';
import i18next from 'i18n';
import Container from '../Layout/Container';
import Button from './Button';

type IErrorBoundaryProps = {
    children: React.ReactNode;
};

type IErrorBoundaryState = {
    hasError: boolean;
};

class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
    constructor(props: IErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        Bugsnag.notify(error);
    }

    render() {
        const { children } = this.props;
        const { hasError } = this.state;

        if (hasError) {
            return (
                <Container className="font-heading capitalize py-24 flex flex-col justify-center items-center">
                    <h1 className="mb-6 text-2xl text-main text-center w-full ">{i18next.t('general.something-went-wrong')}</h1>
                    <Button onClick={() => window.location.replace('/')}>{i18next.t('button-text.go-home')}</Button>
                </Container>
            );
        }
        return children;
    }
}

export default ErrorBoundary;
