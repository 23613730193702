import { User, UserAlert } from '@/api';
import LogoSmall from '@/components/general/LogoSmall';
import { ResponsiveImage } from '@/components/general/ResponsiveImage';
import { trackClick } from '@/helpers/Analytics';
import i18next from 'i18next';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import { useCallback } from 'react';
import BCSpacer from 'public/assets/icons/chevron-right-long.svg';

import TimeAgo from 'javascript-time-ago';

// English.
import en from 'javascript-time-ago/locale/en';

TimeAgo.addDefaultLocale(en);

// Create formatter (English).
const timeAgo = new TimeAgo('en-US');

const AlertItem = ({ alert, user, onClose }: { alert: UserAlert, user: User | null, onClose: (value: boolean) => void; }) => {
    const router = useRouter();
    const pathname = usePathname();

    let buttonTitle: string = i18next.t('button-text.view-item');

    switch (alert.type) {
        case UserAlert.TypeEnum.AUCTIONWON:
        case UserAlert.TypeEnum.SECONDARYBOUGHT:
            buttonTitle = i18next.t('button-text.view-invoices');
            break;
        case UserAlert.TypeEnum.WELCOME:
            if (user?.onboarding_status !== 'done') {
                buttonTitle = i18next.t('button-text.finish-onboarding');
            }
            break;
        case UserAlert.TypeEnum.ONBOARDINGREMINDER:
            if (user?.onboarding_status !== 'done') {
                buttonTitle = i18next.t('button-text.finish-onboarding');
            }
            break;
        default:
            break;
    }
    const urlForBlock: string = [UserAlert.TypeEnum.WELCOME, UserAlert.TypeEnum.ONBOARDINGREMINDER].includes(alert.type) && user?.onboarding_status !== 'done' ? `/onboarding?returnUrl=${pathname}` : alert.link;

    const title = useCallback(() => {
        switch (alert.type) {
            case UserAlert.TypeEnum.AUCTIONENDING:
                return i18next.t('alerts.auction-ending');
            case UserAlert.TypeEnum.AUCTIONOUTBID:
                return i18next.t('alerts.auction-outbid');
            case UserAlert.TypeEnum.AUCTIONSTARTING:
                return i18next.t('alerts.auction-starting');
            case UserAlert.TypeEnum.AUCTIONWON:
                return i18next.t('alerts.auction-won');
            case UserAlert.TypeEnum.ONBOARDINGREMINDER:
                return i18next.t('alerts.onboarding-reminder');
            case UserAlert.TypeEnum.WELCOME:
                return i18next.t('alerts.welcome');
            case UserAlert.TypeEnum.SECONDARYBOUGHT:
                return i18next.t('alerts.secondary-bought');
            case UserAlert.TypeEnum.SECONDARYSOLD:
                return i18next.t('alerts.secondary-sold');
            case UserAlert.TypeEnum.SECONDARYINREAUTHENTICAATION:
                return i18next.t('alerts.secondary-item-received');
            case UserAlert.TypeEnum.OFFERCOUNTERED:
                return i18next.t('alerts.offer.countered');
            case UserAlert.TypeEnum.OFFERCOUNTERREJECTED:
                return i18next.t('alerts.offer.counter-rejected');
            case UserAlert.TypeEnum.OFFERNEW:
                return i18next.t('alerts.offer.new');
            case UserAlert.TypeEnum.OFFERREJECTED:
                return i18next.t('alerts.offer.rejected');
            default:
                return null;
        }
    }, [alert]);

    return (
        <li
            key={alert.id}
            onClick={() => {
                if (urlForBlock) {
                    trackClick(`Alert:${buttonTitle}`, () => onClose(false))();
                    router.push(urlForBlock);
                    onClose(false);
                }
            }}
            className="flex cursor-pointer flex-row space-x-[16px] hover:bg-[#e8e8e8] transition-all px-[24px] py-[18px] border-b-[1px] ">
            {alert.image_url ? <ResponsiveImage src={alert.image_url} imageWidth={48} alt="none" className="rounded-full w-[48px] h-[48px] bg-main min-w-[48px] object-cover" /> : <LogoSmall className="[&>path]:fill-main" />}
            <div className="w-full">
                <div className="flex flex-col justify-center h-full">
                    <div>
                        <div className="flex justify-between items-center">
                            <h3 className="text-[16px] font-[500] tracking-[-0.2px] text-[#1D2939] font-secondary mb-[10px]">{title()}</h3>
                            <span className="text-[14px] font-[400] tracking-[-0.02px] font-secondary text-grey15">{timeAgo.format(new Date(alert.created_at))}</span>
                        </div>
                        <span className="font-[400] mr-[8px] tracking-[-0.2px] text-grey15 inline-block text-[14px] self-start font-secondary">{alert.message}</span>
                    </div>
                    {urlForBlock &&
                        <Link prefetch={false} href={urlForBlock} onClick={trackClick(`Alert:${buttonTitle}`, () => onClose(false))} className="font-[500] font-secondary text-[14px] cursor-pointer self-start mt-[20px] text-[#2D5271] flex items-center gap-[8px]">
                            {buttonTitle}<BCSpacer />
                        </Link>
                    }
                </div>
            </div>
        </li>
    );
};
export default AlertItem;
