import { CMS } from '@/types/CMS';
import React, { createContext, useContext, useState } from 'react';

export type GlobalDataContextType = {
    setGlobal: (data: CMS.Global.Data) => void
    globalData: CMS.Global.Data;
};

const GlobalDataContext = createContext<GlobalDataContextType>({
    setGlobal: (data: CMS.Global.Data) => {},
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    globalData: {} as any
});

const useProvideGlobalData = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [globalData, setGlobalData] = useState<CMS.Global.Data>({} as any);
    const setGlobal = (data: CMS.Global.Data) => { setGlobalData(data); };
    return { setGlobal, globalData };
};

export const GlobalDataProvider = ({ children }: { children: React.ReactNode; }) => {
    const useGlobalData = useProvideGlobalData();
    return (
        <GlobalDataContext.Provider value={useGlobalData}>
            {children}
        </GlobalDataContext.Provider>
    );
};

export const useGlobalData = () => {
    return useContext(GlobalDataContext);
};

export default GlobalDataProvider;
