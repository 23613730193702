import { getDevice } from '@/api/config';
import Button from '@/components/general/Button';
import TextInput from '@/components/general/TextInput';
import useAuth from '@/contexts/AuthContext';
import { ErrorHandler } from '@/helpers/ErrorHandler';
import { forgotPassQueryFn, googleLoginQueryFn, loginQueryFn } from '@/queries/auth';
import { useGoogleLogin } from '@react-oauth/google';
import { useMutation } from '@tanstack/react-query';
import { Formik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

import EnvelopeIcon from 'public/assets/icons/envelope.svg';
import LockIcon from 'public/assets/icons/lock.svg';
import { AuthDto } from '@/api';
import Link from 'next/link';
import i18next from 'i18n';
import { getLandingPageTrackingFromLocalStorage } from '@/helpers/LandingPageTracker';
import SocialLoginButton from '../SocialLoginButton';
import OtpForm from './OtpForm';

const LoginSchema = Yup.object().shape({
    email: Yup.string().email(i18next.t('error-text.invalid-email')).required(i18next.t('error-text.required')),
    password: Yup.string()
        .required(i18next.t('error-text.required'))
});

const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email(i18next.t('error-text.invalid-email')).required(i18next.t('error-text.required'))
});

export type ILoginForm = {
    changeToSignup: () => void;
    onLoginSuccess?: () => void;
    isMobile?: boolean;
};

const LoginForm = (props: ILoginForm) => {
    const [isLoginEmail, setIsLoginEmail] = useState<boolean>(false);
    const [isForgotPassword, setIsForgotPassword] = useState<boolean>(false);
    const [showOtp, setShowOtp] = useState<boolean>(false);
    const [fourDigits, setFourDigits] = useState<string>('');
    const { isMobile = false } = props;

    const { changeToSignup, onLoginSuccess } = props;

    const { setUser, updateRefreshToken } = useAuth();

    const onLogin = (auth: AuthDto) => {
        const { user: resUser, refresh_token: refreshToken } = auth;

        if (refreshToken && refreshToken !== '') {
            updateRefreshToken(refreshToken);
        }
        if (resUser.is_two_factor_enabled) {
            setShowOtp(true);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            setFourDigits(resUser.two_factor_phone_number_last4!);
        } else {
            setUser(resUser);
            if (onLoginSuccess) {
                onLoginSuccess();
            }
        }
    };

    const loginMutation = useMutation({
        mutationFn: loginQueryFn,
        onSuccess: (response) => {
            if (response && response.user) {
                onLogin(response);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if ((window as any).dataLayer) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (window as any).dataLayer.push({
                        event: 'login'
                    });
                }
            }
        },
        onError: async (error) => {
            const transformedError = await ErrorHandler.transformError(error as Request);
            if (ErrorHandler.Auth.unauthorized(transformedError)) {
                toast.error(i18next.t('error-notification.invalid-login'));
            } else {
                toast.error(i18next.t('error-notification.unexpected-error'));
            }
        },
    });

    const googleLoginMutation = useMutation({
        mutationFn: googleLoginQueryFn,
        onSuccess: (response) => {
            if (response && response.user) {
                onLogin(response);
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if ((window as any).dataLayer) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (window as any).dataLayer.push({
                    event: response.created ? 'signup' : 'login'
                });
            }
        },
        onError: async (error) => {
            const transformedError = await ErrorHandler.transformError(error as Request);
            if (ErrorHandler.Auth.unauthorized(transformedError)) {
                toast.error(i18next.t('error-notification.failed-google-login'));
            } else {
                toast.error(i18next.t('error-notification.unexpected-error'));
            }
        },
    });

    const googleLogin = useGoogleLogin({
        flow: 'auth-code',
        onSuccess: async (tokenResponse) => {
            googleLoginMutation.mutate({
                code: tokenResponse.code,
                device: await getDevice(),
                signed_up_from: getLandingPageTrackingFromLocalStorage()
            });
        },
    });

    const forgotPassword = useMutation({
        mutationFn: forgotPassQueryFn,
        onSuccess: () => {
            toast.success(i18next.t('success-notification.existing-email'));
            setIsForgotPassword(false);
        },
        onError: async (error) => {
            toast.error(i18next.t('error-notification.failed-send-verification-email'));
        },
    });

    const loginInputFields = useMemo(() => {
        return (
            <motion.div key="loginform" className="space-y-[12px]" exit={{ opacity: 0 }} animate={{ opacity: 1 }}>
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                    }}
                    validationSchema={LoginSchema}
                    onSubmit={async (values) => {
                        try {
                            loginMutation.mutate({
                                body: {
                                    device: await getDevice(),
                                    email_address: values.email,
                                    password: values.password,
                                }
                            });
                        } catch (error) {
                            toast.error(i18next.t('error-notification.recapcha'));
                        }
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="space-y-3 mt-4 relative">
                                <div>
                                    <TextInput
                                        autoFocus
                                        errored={errors.email && touched.email}
                                        type="text"
                                        placeholder=""
                                        inputPlaceholder={i18next.t('input-text.email')}
                                        name="email"
                                        autoComplete="username"
                                        onChange={handleChange}
                                        value={values.email}
                                        icon={<EnvelopeIcon />}
                                    />
                                    {errors.email && touched.email ? (
                                        <div className="font-secondary text-[0.8rem] text-red text-left">
                                            {errors.email}
                                        </div>
                                    ) : null}
                                </div>
                                <div>
                                    <TextInput
                                        errored={
                                            errors.password && touched.password
                                        }
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        placeholder=""
                                        inputPlaceholder={i18next.t('input-text.password')}
                                        onChange={handleChange}
                                        value={values.password}
                                        icon={<LockIcon />}
                                    />
                                    {errors.password && touched.password ? (
                                        <div className="font-secondary text-[0.8rem] text-red text-left">
                                            {errors.password}
                                        </div>
                                    ) : null}
                                </div>
                                <Button
                                    fullWidth
                                    loading={loginMutation.isLoading || googleLoginMutation.isLoading}
                                    disabled={loginMutation.isLoading || googleLoginMutation.isLoading}
                                    type="submit"
                                >
                                    {i18next.t('signin', { ns: 'header' })}
                                </Button>
                                <span className="font-secondary text-[14px] font-[500] block"><button onClick={() => setIsForgotPassword(true)}>{i18next.t('button-text.forgot-password')}</button></span>
                            </div>
                        </form>
                    )}
                </Formik>
            </motion.div>
        );
    }, [googleLoginMutation.isLoading, loginMutation]);

    const oAuthOptions = useMemo(() => {
        return (
            <motion.div key="loginoptions" className="space-y-[12px]" exit={{ opacity: 0 }} animate={{ opacity: 1 }}>
                {/* <SocialLoginButton
                    network="apple"
                    disabled
                    onClick={() => {

                    }}
                >
                    APPLE
                </SocialLoginButton> */}
                <SocialLoginButton
                    network="google"
                    onClick={googleLogin}
                >
                    Google
                </SocialLoginButton>
                {isMobile ? (
                    <SocialLoginButton
                        network="email"
                        onClick={() => {
                            setIsLoginEmail(true);
                        }}
                    >
                        {i18next.t('general.login-with-email')}
                    </SocialLoginButton>
                ) : null}
            </motion.div>
        );
    }, [googleLogin, isMobile]);

    const forgotPasswordInputs = useMemo(() => {
        return (
            <motion.div key="forgotpwform" className="space-y-[12px]" exit={{ opacity: 0 }} animate={{ opacity: 1 }}>
                <Formik
                    initialValues={{
                        email: ''
                    }}
                    validationSchema={ForgotPasswordSchema}
                    onSubmit={async (values) => {
                        forgotPassword.mutate({
                            email_address: values.email
                        });
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="space-y-3 relative">
                                <div>
                                    <TextInput
                                        autoFocus
                                        errored={errors.email && touched.email}
                                        type="text"
                                        inputPlaceholder={i18next.t('input-text.email')}
                                        name="email"
                                        autoComplete="username"
                                        onChange={handleChange}
                                        value={values.email}
                                        icon={<EnvelopeIcon />}
                                    />
                                    {errors.email && touched.email ? (
                                        <div className="font-secondary text-[0.8rem] text-red text-left">
                                            {errors.email}
                                        </div>
                                    ) : null}
                                </div>
                                <Button
                                    fullWidth
                                    loading={forgotPassword.isLoading}
                                    disabled={forgotPassword.isLoading}
                                    type="submit"
                                >
                                    {i18next.t('button-text.reset-password')}
                                </Button>
                                <span className="font-secondary text-[14px] font-[500] block"><button onClick={() => setIsForgotPassword(false)}>{i18next.t('button-text.back-to-login')}</button></span>
                            </div>
                        </form>
                    )}
                </Formik>
            </motion.div>
        );
    }, [forgotPassword]);

    return (
        <div>
            <div className="text-center space-y-[24px] flex flex-col">
                <div>
                    <span className="text-[16px] bg-gradient-blue bg-clip-text text-transparent font-[500] mb-[4px]">{i18next.t('general.log-in')}</span>
                    <h3 className="font-heading text-[48px] leading-[48px]">{i18next.t('general.welcome-back')}</h3>
                </div>
                <div className="font-[500]">
                    <AnimatePresence mode="wait">
                        {isMobile ? (
                            <>
                                {!isForgotPassword && !isLoginEmail && !showOtp ? oAuthOptions : null}
                                {isLoginEmail && !isForgotPassword && !showOtp ? loginInputFields : null}
                                {isLoginEmail && isForgotPassword && !showOtp ? forgotPasswordInputs : null}

                            </>
                        ) : (
                            <>
                                {!isForgotPassword && !showOtp ? oAuthOptions : null}
                                {!isForgotPassword && !showOtp ? <div className="my-4 font-secondary text-sm font-[100] bg-gradient-blue bg-clip-text text-transparent">{i18next.t('general.or-login-with-email')}</div> : null}
                                {!isForgotPassword && !showOtp ? loginInputFields : null}
                                {isForgotPassword && !showOtp ? forgotPasswordInputs : null}
                            </>
                        )}
                        {showOtp &&
                            <motion.div key="loginform" className="space-y-[12px]" exit={{ opacity: 0 }} animate={{ opacity: 1 }}>
                                <OtpForm onLoginSuccess={onLoginSuccess} fourDigits={fourDigits ?? ''} />
                            </motion.div>
                        }
                    </AnimatePresence>

                </div>
                {!showOtp && (
                    <>
                        <span className="text-[14px] font-[500] block">{`${i18next.t('general.new-here')} `}<button onClick={() => changeToSignup()} className="text-blue3 font-[700]">{i18next.t('general.create-account')}</button></span>
                        <span className="block text-[14px] leading-[140%] pt-[24px]">{`${i18next.t('disclaimer.authentication-form.pt1')} `}<Link href="/privacy-policy" target="__blank">{i18next.t('disclaimer.authentication-form.pt2')}</Link>{`. ${i18next.t('disclaimer.authentication-form.pt3')} `}<Link href="/terms-of-service" target="__blank">{i18next.t('disclaimer.authentication-form.pt4')}</Link>.</span>
                    </>
                )}
            </div>
        </div>
    );
};

export default LoginForm;
