import { userApi } from './auth';

export const getAlerts = async () => {
    return userApi.userControllerAlerts();
};

export const readAlerts = async () => {
    return userApi.userControllerReadAlerts();
};

export const clearAlerts = async () => {
    return userApi.userControllerClearAlerts();
};
