import clsx from 'clsx';
import i18next from 'i18next';

export default function Loader({ fullScreen }: { fullScreen?: boolean }) {
    return (
        <div className={clsx('inset-0 flex items-center justify-center z-50  bg-opacity-40 bg-gray-400 h-[700px]', {
            '!h-[100vh]': fullScreen
        })}>
            <div role="status" className="animate-spin">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none" className="scale-150">
                    <path d="M39.2028 17.867C38.4512 16.9853 38.2105 15.7753 38.5687 14.6724C39.0739 13.1133 38.3725 11.4162 36.9138 10.6715C35.8828 10.1446 35.1985 9.11924 35.1072 7.96276C34.9783 6.32841 33.6827 5.02937 32.0506 4.90049C30.8964 4.80925 29.8723 4.1238 29.3465 3.0905C28.6029 1.6295 26.9092 0.92695 25.3524 1.43334C24.2507 1.79146 23.0429 1.55081 22.1624 0.798073C20.917 -0.266024 19.0842 -0.266024 17.8387 0.798073C16.9583 1.55081 15.7505 1.79146 14.6487 1.43334C13.0919 0.92695 11.3983 1.6295 10.6547 3.0905C10.1289 4.1238 9.1047 4.80925 7.9505 4.90049C6.31843 5.02937 5.02167 6.32727 4.89279 7.96276C4.80155 9.11924 4.11724 10.1446 3.08622 10.6715C1.62751 11.4162 0.926095 13.1122 1.43134 14.6724C1.78946 15.7753 1.54881 16.9853 0.797217 17.867C-0.265739 19.1135 -0.265739 20.9498 0.797217 22.1975C1.54881 23.0791 1.78946 24.2892 1.43134 25.3921C0.926095 26.9511 1.62751 28.6482 3.08622 29.3918C4.11838 29.9187 4.80269 30.9441 4.89279 32.1005C5.02167 33.7349 6.31729 35.0328 7.9505 35.1628C9.1047 35.254 10.1289 35.9395 10.6547 36.9728C11.3983 38.4338 13.0919 39.1364 14.6487 38.63C15.7505 38.2718 16.9583 38.5125 17.8387 39.2652C19.0842 40.3305 20.917 40.3305 22.1624 39.2652C23.0429 38.5125 24.2507 38.2718 25.3524 38.63C26.9092 39.1364 28.6029 38.4338 29.3465 36.9728C29.8723 35.9395 30.8964 35.254 32.0506 35.1628C33.6827 35.0339 34.9795 33.736 35.1072 32.1005C35.1985 30.9441 35.8828 29.9187 36.9138 29.3918C38.3725 28.6471 39.0739 26.9511 38.5687 25.3921C38.2105 24.2892 38.4512 23.0791 39.2028 22.1975C40.2657 20.9509 40.2657 19.1147 39.2028 17.867Z" fill="#001F30" />
                    <path d="M29.6436 27.6839C29.4166 27.4991 29.0345 27.0326 28.4962 26.2845L24.387 20.4986C25.4294 20.2386 26.2403 19.8474 26.8197 19.3262C27.7275 18.5028 28.1814 17.4603 28.1814 16.2001C28.1814 15.1668 27.8792 14.2658 27.2736 13.4959C26.668 12.7272 25.8867 12.2003 24.9287 11.914C24.0254 11.6449 22.6283 11.5023 20.7373 11.4875H20.7282L20.3906 11.4863H9.1543V16.1122H9.63331C9.82606 14.852 10.2891 13.8814 11.0202 13.2005C11.5414 12.7135 12.3774 12.4694 13.5282 12.4694H14.7633V25.6549C14.7633 26.5114 14.7166 27.0418 14.6242 27.2436C14.4987 27.5299 14.3254 27.7306 14.1075 27.8481C13.9912 27.9165 13.8555 27.9667 13.7095 28.0089C13.4073 28.0773 12.9887 28.1127 12.4492 28.1127H12.3432V28.5792H21.2928V28.1127H21.197C20.6313 28.1127 20.1979 28.0751 19.8922 27.9998C19.7759 27.9633 19.6664 27.9211 19.5717 27.8663C19.29 27.7021 19.1007 27.5116 19.0049 27.2927C18.9079 27.0737 18.86 26.5285 18.86 25.6538V21.0894V19.4722V12.4717H20.1762C21.5288 12.4717 22.5097 12.7831 23.1199 13.4047C23.7289 14.0262 24.0334 14.9238 24.0334 16.2263C24.0334 17.1262 23.868 17.8549 23.535 18.4138C23.2031 18.9726 22.7469 19.3593 22.1675 19.5737C21.6577 19.7619 20.9141 19.8657 19.9481 19.8885V20.7906L25.4568 28.5803H30.8388V28.1138C30.3849 28.0887 29.9857 27.9462 29.6413 27.685" fill="#F1F2F2" />
                </svg>
                <span className="sr-only">{i18next.t('common:general.loading')}</span>
            </div>
        </div>
    );
}
