import EmptyState from '@/components/general/EmptyState';
import AlertItem from '@/components/Layout/Header/Alerts/AlertItem';
import useAuth from '@/contexts/AuthContext';
import { useAlerts } from '@/hooks/api/useAlerts';
import { Transition } from '@headlessui/react';
import { BellIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import i18next from 'i18n';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';

const Alerts = ({ onOpen }: { onOpen?: () => void }) => {
    const [open, setOpen] = useState<boolean>(false);
    const { user } = useAuth();
    const ref = useDetectClickOutside({ onTriggered: () => setOpen(false) });
    const { alerts, markRead, clear } = useAlerts();

    const unreadAlertsCount = useMemo(() => (alerts || []).filter(({ read }) => !read).length, [alerts]);

    useEffect(() => {
        if (open && unreadAlertsCount > 0) {
            markRead();
        }
        if (open && onOpen) {
            onOpen();
        }
    }, [open, unreadAlertsCount, markRead, onOpen]);

    return (
        <>

            <AnimatePresence>
                {open && <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 0.7 }}
                    exit={{ opacity: 0 }}
                    className="fixed inset-0 backdrop-blur-[3px] bg-black z-[100] " />}
            </AnimatePresence>
            <div className={clsx('relative', open ? 'z-[101]' : 'z-[9]')} ref={ref}>
                <button
                    type="button"
                    className="flex justify-between items-center text-left"
                    onClick={() => setOpen(!open)}
                >
                    <div className="text-main mr-[12px] bg-white relative rounded-full text-[18px] h-[46px] flex justify-between items-center border-[1px] border-grey9 pl-[16px] pr-[16px] hover:bg-grey12">
                        {unreadAlertsCount > 0 ? (
                            <div className="absolute left-[26px] top-[12px] bg-grey12 p-[2px] rounded-full">
                                <div className="w-[7px] h-[7px] bg-red rounded-full animate-ping absolute opacity-75" />
                                <div className="w-[7px] h-[7px] bg-red rounded-full absolute" />
                            </div>
                        ) : null}
                        <BellIcon className="w-[20px] h-[20px] mr-[5px]" />{' '}{unreadAlertsCount > 0 ? unreadAlertsCount : 0}
                    </div>
                </button>
                <Transition
                    as={Fragment}
                    show={open}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-1"
                    leaveTo="opacity-0 translate-y-0"
                >
                    <div className="font-secondary md:absolute fixed right-0 md:top-11 top-[64px] border-[1px]  border-grey9 shadow-default md:rounded-xl p-0 bg-white w-[100vw] md:w-[468px] flex flex-col justify-center items-center max-h-[80vh]">
                        <div className="flex flex-row justify-between items-center w-full border-b-[1px] pb-[12px] px-[24px] py-[12px]">
                            <h4 className="font-heading capitalize text-[28px]">{i18next.t('common:general.alerts')} {unreadAlertsCount > 0 ? `(${unreadAlertsCount})` : null}</h4>
                            {alerts && alerts.length > 0 ? <button onClick={clear} className="text-[14px] font-[400] underline">{i18next.t('button-text.clear-all')}</button> : null}
                        </div>
                        <ul className="w-full overflow-scroll">
                            {alerts?.map((alert) => <AlertItem user={user} alert={alert} key={alert.id} onClose={setOpen} />)}
                        </ul>
                        {!alerts || alerts.length === 0 ? EmptyState({
                            title: i18next.t('header:notifications.empty-state-title'),
                            subtitle: i18next.t('header:notifications.empty-state-subtitle'),
                            noPadding: true,
                            actionText: i18next.t('general.marketplace'),
                            pathRoute: '/marketplace',
                            onLinkClick: () => { setOpen(false); }
                        }) : null}
                    </div>
                </Transition>

            </div>
        </>
    );
};

export default Alerts;
