import { ResponsiveImage } from '@/components/general/ResponsiveImage';
import useAuth from '@/contexts/AuthContext';
import useSiteSettings, { IMenuItem } from '@/hooks/useSiteSettings';
import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import i18next from 'i18n';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';

const LoggedInUser = ({ onOpen }: { onOpen?: () => void }) => {
    const router = useRouter();
    const pathname = usePathname();
    const [open, setOpen] = useState<boolean>(false);
    const ref = useDetectClickOutside({ onTriggered: () => setOpen(false) });
    const { userMenu } = useSiteSettings();
    const { user, logOut } = useAuth();

    useEffect(() => {
        if (open && onOpen) {
            onOpen();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const isActive = useCallback((href: string) => {
        return href === pathname && pathname.includes('settings') === href.includes('settings');
    }, [pathname]);

    if (!user) {
        return null;
    }

    return (
        <div className="relative" ref={ref}>
            <button
                type="button"
                className="flex justify-between items-center text-left "
                onClick={() => setOpen(!open)}
            >
                <div className="text-white bg-main rounded-full text-[18px] w-[42px] h-[42px] flex justify-center items-center">
                    {user.profile_image_url ? <ResponsiveImage alt="User Profile" className="rounded-full w-full h-full object-cover" src={user.profile_image_url} imageWidth={36} /> : user?.username?.substring(0, 1)}
                </div>
            </button>
            <Transition
                as={Fragment}
                show={open}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-1"
                leaveTo="opacity-0 translate-y-0"
            >
                <div className="md:absolute fixed right-0 md:top-11 top-[64px] shadow-default md:rounded-[8px] p-0 bg-white md:w-[252px] w-[100vw] flex flex-col  overflow-hidden ">
                    <div className="border-b-[1px] border-grey14 px-[16px] py-[16px] text-[16px] mx-[8px] font-[500] text-grey15">{user.first_name} {user.last_name}</div>
                    <ul className="w-full p-[8px]">
                        {userMenu.items.map((item: IMenuItem, i: number) => (
                            <li
                                key={item.title}
                                className={clsx(
                                    'text-[16px]   hover:bg-grey14  border-b-[1px] md:border-none capitalize p-[16px] rounded-[4px]',
                                    isActive(item.href) && 'text-main bg-grey6'
                                )}
                            >
                                <Link
                                    prefetch={false}
                                    onClick={(() => setOpen(false))}
                                    href={item.href}
                                    className="flex justify-start items-center gap-[16px]"
                                >
                                    {item.icon}
                                    <span className="text-[14px] font-[500] block tracking-[-0.2px]">{item.title}</span>
                                </Link>
                            </li>
                        ))}
                        {/** <li
                            className={clsx(
                                'text-[16px]   hover:bg-grey14  capitalize p-[16px] rounded-[4px] border-t-[1px] border-grey14',
                            )}
                        >
                            <button
                                className="flex gap-[16px] items-center w-full"
                                onClick={() => {
                                    setShowRegisterItem(true);
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M12.6667 2H3.33333C2.59695 2 2 2.59695 2 3.33333V12.6667C2 13.403 2.59695 14 3.33333 14H12.6667C13.403 14 14 13.403 14 12.6667V3.33333C14 2.59695 13.403 2 12.6667 2Z" stroke="black"
                                     strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8 5.3335V10.6668" stroke="black" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M5.3335 8H10.6668" stroke="black" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className="text-[14px] font-[500] block tracking-[-0.2px]">{i18next.t('common:offers.register-an-item')}</span>
                            </button>
                        </li>* */}
                        <li
                            className={clsx(
                                'text-[16px]   hover:bg-grey14   md:border-none capitalize p-[16px] rounded-[4px]',
                            )}
                        >
                            <button
                                className="flex gap-[16px] items-center w-full text-[#D92D20]"
                                onClick={() => {
                                    router.push('/');
                                    logOut();
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-4 h-4"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                                    />
                                </svg>
                                <span className="text-[14px] font-[500] block tracking-[-0.2px]">{i18next.t('header:logout')}</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </Transition>
            {/** <ProductRegistrationModal show={showRegisterItem} onHide={() => setShowRegisterItem(false)} /> * */}
        </div>

    );
};

export default LoggedInUser;
