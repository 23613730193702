import Button from '@/components/general/Button';
import { useScrollLock } from 'usehooks-ts';
import { motion, stagger, useAnimate } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import i18next from 'i18n';
import ChevronIcon from 'public/assets/icons/chevron-down.svg';

import { usePathname, useRouter } from 'next/navigation';
import useAuth from '@/contexts/AuthContext';
import { CMS } from '@/types/CMS';
import useSiteSettings from '@/hooks/useSiteSettings';
import clsx from 'clsx';

const staggerMenuItems = stagger(0.1, { startDelay: 0.15 });

function useMenuAnimation(isOpen: boolean) {
    const [scope, animate] = useAnimate();

    useEffect(() => {
        animate(
            '.wrapper',
            {
                opacity: isOpen
                    ? 1
                    : 0,
                clipPath: 'unset'
            },
            {
                type: 'spring',
                bounce: 0,
                duration: 0.5
            }
        );

        animate(
            'li',
            isOpen
                ? { opacity: 1, scale: 1, filter: 'blur(0px)' }
                : { opacity: 0, scale: 0.3, filter: 'blur(20px)' },
            {
                duration: 0.2,
                delay: isOpen ? staggerMenuItems : 0
            }
        );
    }, [animate, isOpen]);

    return scope;
}

const MobileNavigation = ({ open, mainMenu, search }: { open: boolean, search: boolean; mainMenu: CMS.Global.Menu[] }) => {
    useScrollLock({ autoLock: open, lockTarget: 'root' });
    const scope = useMenuAnimation(open);
    const [searchValue, setSearchValue] = useState<string>('');
    const [searchLoading, setSearchLoading] = useState<boolean>(false);
    const [accountExpanded, setAccountExpanded] = useState<boolean>(false);

    const { userMenu } = useSiteSettings();

    const router = useRouter();
    const pathname = usePathname();
    const { user, logOut } = useAuth();
    const searchRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!open) {
            setSearchValue('');
            setSearchLoading(false);
            setAccountExpanded(false);
        }
        if (open && search && searchRef) {
            searchRef.current?.focus();
        }
    }, [open, search, searchRef]);

    return (
        <nav ref={scope}>
            <div
                style={{
                    pointerEvents: open ? 'auto' : 'none',
                }}
                className="bg-white bg-opacity-[0.85] backdrop-blur-md fixed inset-0 md:top-[80px] top-[66px] flex justify-between flex-col wrapper opacity-0">
                <div className="overflow-scroll">
                    <motion.div initial={{ height: 0 }} animate={{ height: search ? 69 : 0 }} exit={{ height: 0 }} className="h-0 overflow-hidden">
                        <div className="bg-grey11 p-4 border-b-[1px] border-grey9 flex flex-row space-x-[10px]">
                            <input ref={searchRef} type="text" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder="Search..." className="bg-white rounded-full w-full border-[1px] px-[10px] py-[5px]" />
                            <Button
                                loading={searchLoading}
                                disabled={searchLoading}
                                onClick={() => {
                                    setSearchLoading(true);
                                    router.push(`/search/${encodeURIComponent(searchValue)}`);
                                }}>{i18next.t('header:mobile.search-go')}
                            </Button>
                        </div>
                    </motion.div>
                    <div className="px-[32px] pt-[24px]">
                        <ul>
                            {mainMenu && mainMenu.map((item) => {
                                return (
                                    <li key={item.id} className="font-secondary text-[#475467] capitalize font-[500] text-[20px] mb-[34px]">
                                        <Link href={item.path}>
                                            {item.title}
                                        </Link>
                                    </li>
                                );
                            })}
                            {user && (
                                <>
                                    <li className={clsx('font-secondary text-[#475467] capitalize font-[500] text-[20px] mb-[24px] flex items-center')} onClick={() => setAccountExpanded(!accountExpanded)}>
                                        <motion.span animate={{ transform: accountExpanded ? 'rotate(180deg)' : 'rotate(0deg)' }} className="w-[30px] h-[27px] hover:bg-grey14 rounded-full flex justify-center items-center">
                                            <ChevronIcon />
                                        </motion.span>
                                        <span className="ml-2">{i18next.t('header:navigation.account')}</span>

                                    </li>
                                    <motion.div animate={{ height: accountExpanded ? 'fit-content' : 0 }} className={clsx('text-grey15 text-[14px] font-[500] mt-[10px] overflow-hidden h-0')}>
                                        {userMenu.items.slice(1).map((item) => {
                                            return (
                                                <li key={item.href} className="pl-[48px] font-secondary text-[#475467] capitalize font-[500] text-[20px] mb-[34px]">
                                                    <Link href={item.href}>
                                                        {item.title}
                                                    </Link>
                                                </li>
                                            );
                                        })}
                                    </motion.div>
                                </>
                            )}
                        </ul>
                    </div>
                </div>
                <div>
                    <div className="px-[32px] my-[24px]">
                        <div className="h-[1px] bg-grey9 mb-[24px]" />
                        <ul>
                            {!user ?
                                <>
                                    <li className="mb-[12px]"><Link prefetch={false} href={{ pathname: '/mobile/signup', query: { redirect: pathname } }}><Button fullWidth>{i18next.t('header:signup')}</Button></Link></li>
                                    <li><Link prefetch={false} href={{ pathname: '/mobile/login', query: { redirect: pathname } }}><Button fullWidth light>{i18next.t('header:signin')}</Button></Link></li>
                                </> :
                                <li className="mb-[12px]"><Button onClick={() => logOut()} fullWidth>{i18next.t('header:logout')}</Button></li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </nav>

    );
};
export default MobileNavigation;
