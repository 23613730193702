import React, { createContext, useContext, useState } from 'react';
import Sheet from 'react-modal-sheet';
import { SheetProps } from 'react-modal-sheet/dist/types';

export type ButtomSheetContextType = {
    show: (data: SheetProps) => void
    hide: () => void
};

const BottomSheetContext = createContext<ButtomSheetContextType>({
    show: (data: SheetProps) => {},
    hide: () => {},
});

const useProvideBottomSheet = () => {
    const [showSheet, setShowSheet] = useState<boolean>(false);
    const [sheet, setSheet] = useState<SheetProps | null>(null);

    const show = (data: SheetProps) => { setSheet(data); setShowSheet(true); };
    const hide = () => { setTimeout(() => setSheet(null), 500); setShowSheet(false); };

    return { show, hide, sheet, showSheet };
};

export const SheetProvider = ({ children }: { children: React.ReactNode; }) => {
    const useBottomSheet = useProvideBottomSheet();
    return (
        <BottomSheetContext.Provider value={useBottomSheet}>

            {children}
            {useBottomSheet.showSheet &&
                <Sheet isOpen onClose={useBottomSheet.hide} tweenConfig={{ ease: 'easeInOut', duration: 0.4 }}>
                    <Sheet.Container>
                        <Sheet.Header />
                        <Sheet.Content>{useBottomSheet?.sheet?.children}</Sheet.Content>
                    </Sheet.Container>
                    <Sheet.Backdrop onTap={useBottomSheet.hide} />
                </Sheet>
            }

        </BottomSheetContext.Provider>
    );
};

export const useBottomSheet = () => {
    return useContext(BottomSheetContext);
};

export default SheetProvider;
