import { useEffect, useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import Video from 'yet-another-react-lightbox/plugins/video';
import isNil from 'lodash.isnil';

export function InjectDynamicVideo() {
    const [index, setIndex] = useState<number>();
    const [urls, setUrls] = useState<string[]>([]);

    useEffect(() => {
        setTimeout(() => {
            const videoElems = document?.getElementsByClassName('lightbox-video');
            const foundUrls: string[] = [];
            if (videoElems?.length) {
                // eslint-disable-next-line no-plusplus, @typescript-eslint/no-shadow
                for (let index = 0; index < videoElems.length; index++) {
                    const elem = videoElems[index];
                    if (elem.hasAttribute('data-video-src')) {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        foundUrls.push(elem.getAttribute('data-video-src')!);
                        elem.addEventListener('click', () => {
                            setIndex(index);
                        });
                    }
                }
            }
            setUrls(foundUrls);
        }, 0);
    }, []);

    if (urls.length) {
        return (
            <Lightbox
                index={index}
                plugins={[Video]}
                slides={urls.map((url) => ({ type: 'video', sources: [{ src: url, type: 'video/mp4' }], autoPlay: true, preload: 'auto', loop: true }))}
                open={!isNil(index)}
                close={() => setIndex(undefined)}
            />
        );
    }
    return null;
}
